import "./src/styles/global.scss"

export const onInitialClientRender = () => {
    setTimeout(function() {
        var fadeTarget = document.getElementById("___loader");
        var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.1;
            } else {
                fadeTarget.remove()
                clearInterval(fadeEffect);
            }
        }, 100);
    }, 1000)
}